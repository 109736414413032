import Modal from './modules/modal.js'; //quite
import objectFitImages from './modules/objectfit.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

import * as Swiper from './extern/swiper.js'; //quite
import * as Fancybox from './extern/jquery.fancybox.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	objectFitImages();
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();

	let introImg = document.querySelector('[data-img="zoom"]');
	let isActive = false; /* Flag for auto-paused video */

	let observer = new IntersectionObserver(
		(entries, observer) => {
			entries.forEach((entry) => {
				if (entry.boundingClientRect.top < 300 && !isActive) {
					introImg.dataset.active = 'true';
					isActive = true;
				} else if (isActive) {
					delete introImg.dataset.active;
					isActive = false;
				}
			});
		},
		{ rootMargin: '0px 0px -80% 0px' }
	);

	if (document.body.contains(introImg)) {
		observer.observe(introImg);
	}

	const elTriggers = document.querySelectorAll('.nav-list li');
	const elNav = document.querySelectorAll('.tabs-nav [data-dir]');

	[...elTriggers].forEach((trigger) => {
		trigger.addEventListener('click', switchTab);
	});

	[...elNav].forEach((trigger) => {
		const dir = trigger.dataset.dir;

		if (dir === 'next') {
			trigger.addEventListener('click', nextTab);
		} else {
			trigger.addEventListener('click', prevTab);
		}
	});

	function switchTab(e) {
		e.preventDefault();
		let elTarget = e.currentTarget.dataset.tab;

		removeActive('.nav-list li[data-active]');
		removeActive('.tab[data-active]');

		e.currentTarget.dataset.active = true;
		document.querySelector(`.tab[data-tab=${elTarget}]`).dataset.active = true;
	}

	function prevTab(e) {
		let prevTab = e.currentTarget.closest('.tab').previousElementSibling;

		if (prevTab === document.querySelector('.nav-list')) {
			prevTab = document.querySelector('.tab:last-child');
		}

		removeActive('.nav-list li[data-active]');
		removeActive('.tab[data-active]');

		e.currentTarget.dataset.active = true;
		document.querySelector(
			`.nav-list li[data-tab=${prevTab.dataset.tab}]`
		).dataset.active = true;

		prevTab.dataset.active = true;
	}

	function nextTab(e) {
		let nextTab = e.currentTarget.closest('.tab').nextElementSibling;

		if (nextTab === null) {
			nextTab = document.querySelector('.tab:first-of-type');
		}

		removeActive('.nav-list li[data-active]');
		removeActive('.tab[data-active]');

		//e.currentTarget.dataset.active = true;
		document.querySelector(
			`.nav-list li[data-tab=${nextTab.dataset.tab}]`
		).dataset.active = true;

		nextTab.dataset.active = true;
	}

	const removeActive = function (selector) {
		[...document.querySelectorAll(selector)].forEach(
			(el) => delete el.dataset.active
		);
	};

	//------------------------------------//
	// ACCORDION
	//------------------------------------//

	const accordionTriggers = document.querySelectorAll(
		'.item-list[data-list="accordion"] .item-trigger'
	);

	[...accordionTriggers].forEach((trigger) => {
		trigger.addEventListener('click', (e) => {
			if (e.currentTarget.parentNode.dataset.active) {
				delete e.currentTarget.parentNode.dataset.active;
			} else {
				e.currentTarget.parentNode.dataset.active = true;
			}
		});
	});

	function switchItem(e) {
		removeActive('.item-list[data-list="accordion"] .item[data-active]');
		e.currentTarget.parentNode.dataset.active = true;
	}
});
